import React, { useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Image } from "react-bootstrap";
// import {CranberryJuice} from "../../assets/Ingredients/Cranberry Juice.PNG"
import CranberryJuice from "../../assets/Ingredients/Cranberry Juice.PNG";
import ElderflowerLiqueur from "../../assets/Ingredients/Elderflower Liqueur.PNG";
import GrapefruitJuice from "../../assets/Ingredients/Grapefruit Juice.PNG";
import LemonJuice from "../../assets/Ingredients/Lemon Juice.PNG";
import LimeJuice from "../../assets/Ingredients/Lime Juice.PNG";
import OrangeJuice from "../../assets/Ingredients/Orange Juice.PNG";
import SimpleSyrup from "../../assets/Ingredients/Simple Syrup.PNG";
import SodaWater from "../../assets/Ingredients/Soda Water.PNG";
import Tequila from "../../assets/Ingredients/Tequila.PNG";
import TonicWater from "../../assets/Ingredients/Tonic Water.PNG";
import TripleSec from "../../assets/Ingredients/Triple Sec.PNG";
import Vodka from "../../assets/Ingredients/Vodka.PNG";
import Whiskey from "../../assets/Ingredients/Whiskey.PNG";
import WhiteRum from "../../assets/Ingredients/White Rum.PNG";
// Updated import statements
import HotToddyWithCinnamonTwist from "../../assets/Newrecipes/Hot Toddy with Cinnamon Twist.jpg";
import MulledWine from "../../assets/Newrecipes/Mulled Wine.jpg";
import NutmegInfusedOldFashioned from "../../assets/Newrecipes/Nutmeg Infused Old Fashioned.jpg";
import SpicedAppleCiderSangria from "../../assets/Newrecipes/Spiced Apple Cider Sangria.jpg";
import WinterSpiceMartini from "../../assets/Newrecipes/Winter Spice Martini.jpg";

import {
  dateOptions,
  AgeOptions,
  userOptions,
  RecipeTypeOptions,
  sortOptions,
  locationOptions,
} from "./../../components/selectvalues/selectvalue.jsx";

import { ModalComponent } from "../expand/expandmodal.jsx";

import { Geomapexpand } from "../expand/geomapexpand.jsx";

import glassshimmer from "../../assets/glassshimmer.png";

function Dashboard() {





  const [statsData, setStatsData] = useState();
  // const [serverData, setServerData] = useState();
  const [countryData, setCountryData] = useState();
  // const [sourceData, setSourceData] = useState();
  // const [sourceChartData, setSourceChartData] = useState();
  const [productData, setProductData] = useState();
  // const [activityLogData, setActivityLogData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  // const [serverChartOptions, setServerChartOptions] = useState(
  //   getServerChartOptions()
  // );
  const [GeoMap, setGeoMap] = useState(renderMap());
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  // console.log(timestampVariable);

  //API Intergration UseState

  const [data, setData] = useState(null); //Dashboard

  const [DashUserTile, setDataUserTile] = useState([]); //BarGraph TRUExpand

  const [CraftedTile, setDataCraftedTile] = useState([]); //LineGraph CraftedCocktailExpand

  const [ActiveTile, setDataActiveTile] = useState([]); //LineGraph CraftedCocktailExpand

  const [ReceipeProductTile, setReceipeProductTile] = useState([]); //Top Products Expands
  const [IngredientsProductTile, setIngredientsProductTile] = useState([]); //Top Products Expands
  const [BrandsProductTile, setBrandsProductTile] = useState([]); //Top Products Expands
  const [BarsProductTile, setBarsProductTile] = useState([]); //Top Products Expands
  const [InfluencerProductTile, setInfluencersProductTile] = useState([]); //Top Products Expands

  const [UserReceipeTile, setUserReceipeTile] = useState([]); //Receipes Expand
  const [InfluencerReceipeTile, setInfluencerReceipeTile] = useState([]); //Receipes Expand
  const [BrandReceipeTile, setBrandReceipeTile] = useState([]); //Receipes Expand

  const [MixReceipeTile, setMixReceipeTile] = useState([]); //Mixlist Expand
  const [MixInfluencersTile, setMixInfluencerTile] = useState([]); //Mixlist Expand
  const [MixBrandTile, setMixBrandTile] = useState([]); //Mixlist Expand

  // console.log(UserReceipeTile, InfluencerReceipeTile, BrandReceipeTile);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //TRUTile
  const [selectedDateOption, setSelectedDateOption] = useState(dateOptions[1]);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedAgeOption, setSelectedAgeOption] = useState(AgeOptions[0]);

  //CraftedTile
  const [craftedselectedDateOption, setCraftedSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [craftedcustomDateRange, setcraftedCustomDateRange] = useState(false);
  const [craftedstartDate, setcraftedStartDate] = useState(new Date());
  const [craftedendDate, setcraftedEndDate] = useState(new Date());
  const [craftedselectedAgeOption, setcraftedSelectedAgeOption] = useState(
    AgeOptions[0]
  );

  //ActiveTile
  const [activeselectedDateOption, setActiveSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [activecustomDateRange, setActiveCustomDateRange] = useState(false);
  const [activestartDate, setActiveStartDate] = useState(new Date());
  const [activeendDate, setActiveEndDate] = useState(new Date());
  const [activeselectedAgeOption, setActiveSelectedAgeOption] = useState(
    AgeOptions[0]
  );

  // Get the current date
  var currentDate = new Date();






  // Convert the current date to a timestamp in seconds
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  // Store the timestamp in a variable

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  // function getServerChartOptions() {
  //   var borderColor = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-border-color")
  //     .trim();
  //   var bodyColor = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-body-color")
  //     .trim();
  //   var inverseRgb = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-inverse-rgb")
  //     .trim();
  //   var themeColor = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-theme")
  //     .trim();
  //   var themeFont = getComputedStyle(document.body)
  //     .getPropertyValue("--bs-body-font-family")
  //     .trim();

  //   return {
  //     chart: { toolbar: { show: false } },
  //     plotOptions: {
  //       bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
  //     },
  //     dataLabels: { enabled: false },
  //     grid: { show: true, borderColor: borderColor },
  //     stroke: { show: false },
  //     colors: ["rgba(" + inverseRgb + ", .15)", themeColor],
  //     legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
  //     xaxis: {
  //       categories: [
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //         "Nov",
  //         "Dec",
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //         "Nov",
  //         "Dec",
  //       ],
  //       labels: { show: false },
  //       axisBorder: {
  //         show: true,
  //         color: borderColor,
  //         height: 1,
  //         width: "100%",
  //         offsetX: 0,
  //         offsetY: -1,
  //       },
  //       axisTicks: {
  //         show: true,
  //         borderType: "solid",
  //         color: borderColor,
  //         height: 6,
  //         offsetX: 0,
  //         offsetY: 0,
  //       },
  //     },
  //     yaxis: {
  //       labels: {
  //         style: {
  //           colors: bodyColor,
  //           fontSize: "12px",
  //           fontFamily: themeFont,
  //           fontWeight: 400,
  //           cssClass: "apexcharts-xaxis-label",
  //         },
  //       },
  //     },
  //     fill: { opacity: 0.65 },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val + " thousands";
  //         },
  //       },
  //     },
  //   };
  // }

  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    const map = document.getElementById("world-map");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#world-map",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: { regions: [{ normalizeFunction: "polynomial" }] },
        labels: { markers: { render: (marker) => marker.name } },
        focusOn: { x: 0.5, y: 0.5, scale: 1 },
        markerStyle: {
          initial: { fill: themeColor, stroke: "none", r: 5 },
          hover: { fill: themeColor },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.35,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: { fill: themeColor, fillOpacity: 0.9 },
        },
        backgroundColor: "transparent",
      });
    }
  }

  // Top Products section
  const producttabs = [
    { id: "receipes", label: "Recipes", active: true },
    { id: "ingredients", label: "Ingredients", active: false },
    { id: "brands", label: "Brands", active: false },
    { id: "bars", label: "Bars", active: false },
    { id: "influencers", label: "Influencers", active: false },
  ];
  const producttabsEX = [
    { id: "receipesproex", label: "Recipes", active: true },
    { id: "ingredientsproex", label: "Ingredients", active: false },
    { id: "brandsproex", label: "Brands", active: false },
    { id: "barsproex", label: "Bars", active: false },
    { id: "influencerprosex", label: "Influencers", active: false },
  ];

  const newreceipestabs = [
    { id: "receipesmain", label: "Users", active: true },
    { id: "ingredientsmain", label: "Influencers", active: false },
    { id: "brandsmain", label: "Brands", active: false },
  ];

  const newreceipestabsEX = [
    { id: "receipesex", label: "Users", active: true },
    { id: "ingredientseex", label: "Influencers", active: false },
    { id: "brandsex", label: "Brands", active: false },
  ];

  const newmixlisttabs = [
    { id: "receipesmix", label: "Recipes", active: true },
    { id: "influencermix", label: "Influencers", active: false },
    { id: "brandsmix", label: "Brands", active: false },
  ];

  const newmixlisttabsEX = [
    { id: "receipesmixex", label: "Recipes", active: true },
    { id: "influencermixex", label: "Influencers", active: false },
    { id: "brandsmixex", label: "Brands", active: false },
  ];

  // TabContent Data

  const tabContent = [
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  useEffect(() => {
    fetch("/assets/data/dashboard/stats.json")
      .then((res) => res.json())
      .then((result) => {
        setStatsData(result);
      });
    // fetch("/assets/data/dashboard/server.json")
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setServerData(result);
    //   });
    fetch("/assets/data/dashboard/country.json")
      .then((res) => res.json())
      .then((result) => {
        setCountryData(result);
      });
    // fetch("/assets/data/dashboard/source.json")
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setSourceData(result);
    //   });
    // fetch("/assets/data/dashboard/source-chart.json")
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setSourceChartData(result);
    //   });
    fetch("/assets/data/dashboard/product.json")
      .then((res) => res.json())
      .then((result) => {
        setProductData(result);
      });
    // fetch("/assets/data/dashboard/activity-log.json")
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setActivityLogData(result);
    //   });

    //API Integration

    fetch("https://api.barsys.ai/barsysanalytics/public/api/dashboardinfo")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // console.log(result);
        setData(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // async function fetchData() {
    //   try {
    //     const response = await fetch(
    //       "https://api.barsys.ai/barsysanalytics/public/api/dashboardinfo"
    //     );
    //     const result = await response.json();
    //     // console.log(result);
    //     setData(result);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // }

    // fetchData();

    renderMap();

    document.addEventListener("theme-reload", () => {
      // setServerChartOptions(getServerChartOptions());
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    DashUserTileInfo(selectedDateOption.value);
  }, [selectedDateOption, selectedAgeOption, startDate, endDate]);

  useEffect(() => {
    CraftedTileInfo(craftedselectedDateOption.value);
  }, [
    craftedselectedDateOption,
    craftedselectedAgeOption,
    craftedstartDate,
    craftedendDate,
  ]);

  useEffect(() => {
    ActiveTileInfo(activeselectedDateOption.value);
  }, [
    activeselectedDateOption,
    activeselectedAgeOption,
    activestartDate,
    activeendDate,
  ]);

  useEffect(() => {
    const Product = async () => {
      try {
        const result = await ProductTileInfo();
        setReceipeProductTile(result.curatedRecipeData);
        setIngredientsProductTile(result.ingredientData);
        setBrandsProductTile(result.brandData);
        setBarsProductTile(result.influencerRecipe);
        setInfluencersProductTile(result.influencerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    Product();
  }, []);

  useEffect(() => {
    const Receipe = async () => {
      try {
        const result = await ReceipesTileInfo();
        setUserReceipeTile(result.userRecipe);
        setInfluencerReceipeTile(result.influencerRecipe);
        setBrandReceipeTile(result.brandRecipe);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    Receipe();
  }, []);

  useEffect(() => {
    const Mixlist = async () => {
      try {
        const result = await MixlistTileInfo();
        setMixReceipeTile(result.userPlaylist);
        setMixInfluencerTile(result.influencerPlaylist);
        setMixBrandTile(result.brandPlaylist);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    Mixlist();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const userreceipescurrentItems = UserReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const influencerreceipescurrentItems = InfluencerReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandreceipescurrentItems = BrandReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const receipesproductscurrentItems = ReceipeProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const ingredientsproductscurrentItems = IngredientsProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandproductscurrentItems = BrandsProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // const barsproductsscurrentItems = BarsProductTile.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );
  const influencerscurrentItems = InfluencerProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const receipesmixlistcurrentItems = MixReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const influencersmixlistcurrentItems = MixInfluencersTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandmixlistcurrentItems = MixBrandTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );




  const userrecipesimageMap = {
    "Hot Toddy with Cinnamon Twist": HotToddyWithCinnamonTwist,
    "Mulled Wine": MulledWine,
    "Nutmeg Infused Old Fashioned": NutmegInfusedOldFashioned,
    "Spiced Apple Cider Sangria": SpicedAppleCiderSangria,
    "Winter Spice Martini": WinterSpiceMartini
};


const baseList = [
  "Bourbon Whiskey",
  "Red Wine",
  "Bourbon Whiskey",
  "White Wine, Brandy",
  "Spiced Rum, Vanilla Vodka",
];
const mixerList = [
  "Honey,Fresh Lemon Juice, Hot Water",
  "Honey",
  "Simple Syrup",
  "Spiced Apple Cider",
  "Cinnamon Syrup, Heavy Cream"
];
const garnishList = [
  "Cinnamon Stick, Lemon Wheel",
  "Whole Orange Sliced, Clove, Cinnamon Stick, Grated Nutmeg",
  "Angostra Bitters, Grated Nutmeg, Orange Twist",
  "Whole Apple Slices, Whole Orange Sliced, Cinnamon Stick",
  "Ground Cinnamon, Cinnamon Stick"
];


const ingredientNamess = Object.keys(userrecipesimageMap);
const staticDataRecipes = ingredientNamess.map((name1, index) => ({
    name1,
    image: userrecipesimageMap[name1],
    // price: `$${getRandomInt(10, 50)}`,
    baseList: baseList[index],
    mixerList: mixerList[index],
    garnishList: garnishList[index],
    brandName: `Brand ${index + 1}`
}));

  const ReceipestabContent = [
    <div className="table-responsive">
    {/* {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) :staticDataRecipes.length === 0 ? (
        <div>No Records Found</div>
      ) : ( */}
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
        
          {staticDataRecipes.length > 0? (staticDataRecipes.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < userreceipescurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name1}</div>
                      <div>Crafted By:{product.username}</div>
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < userreceipescurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td>Base:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.baseList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Mixer:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.mixerList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Garnish:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.garnishList}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
          )) ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
      </tbody>
    </table>
  {/* )} */}
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencerreceipescurrentItems &&
          influencerreceipescurrentItems.length > 0 ? (
            influencerreceipescurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencerreceipescurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name}</div>
                      <div>Crafted By:{product.username}</div>
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencerreceipescurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Base:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.baseList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Mixer:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.mixerlist}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Garnish:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.garnishlist}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
     <div className="table-responsive">
     {/* {isLoading ? (
         <div className="spinner-border text-success pt-2"></div>
       ) :staticDataRecipes.length === 0 ? (
         <div>No Records Found</div>
       ) : ( */}
       <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
         <tbody>
         
           {staticDataRecipes.length > 0? (staticDataRecipes.map((product, index) => (
               <tr key={index} className="product_border">
                 <td>
                   <div className="d-flex" style={{ width: "450px" }}>
                     <div
                       className={
                         "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px" +
                         (index >= 0
                           ? " bg-theme text-theme-900"
                           : " bg-black bg-opacity-50")
                       }
                     >
                       {index + 1}
                     </div>
                     <div
                       className={
                         "position-relative" +
                         (index < userreceipescurrentItems.length - 1
                           ? " mb-2"
                           : "")
                       }
                     >
                       <Image
                         src={`${product.image ? product.image : glassshimmer}`}
                         className="object-fit-cover w-80px h-80px"
                       />
                     </div>
                     <div className="flex-1 ps-3">
                       <div className="fw-500 text-inverse">{product.name1}</div>
                       <div>Crafted By:{product.username}</div>
                       <div className="mb-1">
                         <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                           {"Barsys"}
                         </small>
                       </div>
                     </div>
                   </div>
                 </td>
                 <td>
                   <table
                     className={
                       index < userreceipescurrentItems.length - 1 ? "mb-2" : ""
                     }
                   >
                     <tbody>
                       <tr>
                         <td>Base:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.baseList}
                         </td>
                       </tr>
                       <tr>
                         <td className="pe-2 text-nowrap">Mixer:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.mixerList}
                         </td>
                       </tr>
                       <tr>
                         <td className="pe-2 text-nowrap">Garnish:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.garnishList}
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </td>
               </tr>
           )) ) : (
             <tr>
               <td colSpan="5">No records found</td>
             </tr>
           )}
       </tbody>
     </table>
   {/* )} */}
     </div>,
  ];

  const ReceipestabContentEX = [
    <div className="table-responsive">
    {/* {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) :staticDataRecipes.length === 0 ? (
        <div>No Records Found</div>
      ) : ( */}
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
        
          {staticDataRecipes.length > 0? (staticDataRecipes.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < userreceipescurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name1}</div>
                      <div>Crafted By:{product.username}</div>
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < userreceipescurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td>Base:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.baseList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Mixer:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.mixerList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Garnish:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.garnishList}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
          )) ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
      </tbody>
    </table>
  {/* )} */}
    </div>,
    <div className="table-responsive ">
      {influencerreceipescurrentItems.map((product, index) => (
        <div key={index} className={"mb-3 p-3 mt-3 product_border"}>
          <div className="row ">
            <div className="col-xl-8 col-12 col-md-6 col">
              <div className="d-flex align-items-center justify-content-between">
                <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                  {index + 1}
                </div>
                <div className="position-relative">
                  <Image
                    src={`${product.image ? product.image : glassshimmer}`}
                    className="object-fit-cover w-80px h-80px"
                  />
                </div>
                <div className="flex-1 ps-3">
                  <div className="fw-500 text-inverse">{product.name}</div>
                  <div>Crafted By:{product.username}</div>
                  <div className="mb-1">
                    <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                      {"Barsys"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {/* Code placed in table */}
            <div className="col-xl-4 col-12">
              <div
                className={
                  index < InfluencerReceipeTile.length - 1 ? "mb-2" : ""
                }
              >
                <div className="row">
                  <div className="col-xl-12 col-12">
                    <div>
                      <label>Base:</label>
                      <span className="ms-4">{product.baseList}</span>
                    </div>
                    <div>
                      <label>Mixer:</label>
                      <span className="ms-4">{product.mixerlist}</span>
                    </div>
                    <div>
                      <label>Garnish:</label>
                      <span className="ms-2">{product.garnishlist}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {influencerreceipescurrentItems.length === 0 && (
        <div>No records found</div>
      )}
    </div>,
     <div className="table-responsive">
     {/* {isLoading ? (
         <div className="spinner-border text-success pt-2"></div>
       ) :staticDataRecipes.length === 0 ? (
         <div>No Records Found</div>
       ) : ( */}
       <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
         <tbody>
         
           {staticDataRecipes.length > 0? (staticDataRecipes.map((product, index) => (
               <tr key={index} className="product_border">
                 <td>
                   <div className="d-flex" style={{ width: "450px" }}>
                     <div
                       className={
                         "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px" +
                         (index >= 0
                           ? " bg-theme text-theme-900"
                           : " bg-black bg-opacity-50")
                       }
                     >
                       {index + 1}
                     </div>
                     <div
                       className={
                         "position-relative" +
                         (index < userreceipescurrentItems.length - 1
                           ? " mb-2"
                           : "")
                       }
                     >
                       <Image
                         src={`${product.image ? product.image : glassshimmer}`}
                         className="object-fit-cover w-80px h-80px"
                       />
                     </div>
                     <div className="flex-1 ps-3">
                       <div className="fw-500 text-inverse">{product.name1}</div>
                       <div>Crafted By:{product.username}</div>
                       <div className="mb-1">
                         <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                           {"Barsys"}
                         </small>
                       </div>
                     </div>
                   </div>
                 </td>
                 <td>
                   <table
                     className={
                       index < userreceipescurrentItems.length - 1 ? "mb-2" : ""
                     }
                   >
                     <tbody>
                       <tr>
                         <td>Base:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.baseList}
                         </td>
                       </tr>
                       <tr>
                         <td className="pe-2 text-nowrap">Mixer:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.mixerList}
                         </td>
                       </tr>
                       <tr>
                         <td className="pe-2 text-nowrap">Garnish:</td>
                         <td className="text-inverse text-opacity-75 fw-500 pe-5">
                           {product.garnishList}
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </td>
               </tr>
           )) ) : (
             <tr>
               <td colSpan="5">No records found</td>
             </tr>
           )}
       </tbody>
     </table>
   {/* )} */}
     </div>,
  ];

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function toImagePath(name) {
    return `./assets/images/${name.replace(/\s+/g, '_').toLowerCase()}.jpg`;
}

const imageMap = {
  "Cranberry Juice": CranberryJuice,
  "Elderflower Liqueur": ElderflowerLiqueur,
  "Grapefruit Juice": GrapefruitJuice,
  "Lemon Juice": LemonJuice,
  "Lime Juice": LimeJuice,
  "Orange Juice": OrangeJuice,
  "Simple Syrup": SimpleSyrup,
  "Soda Water": SodaWater,
  "Tequila": Tequila,
  "Tonic Water": TonicWater,
  "Triple Sec": TripleSec,
  "Vodka": Vodka,
  "Whiskey": Whiskey,
  "White Rum": WhiteRum
};


const ingredientNames = Object.keys(imageMap);
const staticData = ingredientNames.map((name, index) => ({
    name,
    baseSponsorImage: imageMap[name],
    // price: `$${getRandomInt(10, 50)}`,
    brandName: `Brand`
}));


const slicedData = staticData.slice(0, 5);

  const TPtabContent = [
    <div className="table-responsive">
      {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) : receipesproductscurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
          <tbody>
            {receipesproductscurrentItems.map((product, index) => (
            <tr key={index} className="product_border">
              <td>
                <div className="d-flex" style={{ width: "450px" }}>
                  <div
                    className={
                      "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                      (index >= 0
                        ? " bg-theme text-theme-900"
                        : " bg-black bg-opacity-50")
                    }
                  >
                    {index + 1}
                  </div>
                  <div
                    className={
                      "position-relative" +
                      (index < receipesproductscurrentItems.length - 1
                        ? " mb-2"
                        : "")
                    }
                  >
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                    Crafted By: {product.username}
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {product.brandName}
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <table
                  className={
                    index < receipesproductscurrentItems.length - 1
                      ? "mb-2"
                      : ""
                  }
                >
                  <tbody>
                    <tr>
                      <td className="pe-2">Views:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {getRandomInt(1000, 10000)}
                      </td>
                      <td className="pe-2">Likes:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {getRandomInt(1000, 10000)}
                      </td>
                      <td className="pe-2">Crafted:</td>
                      <td className="text-inverse text-opacity-75 fw-500 ">
                      {getRandomInt(1000, 10000)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Clicks:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {getRandomInt(1000, 10000)}
                      </td>
                      <td className="pe-2 text-nowrap">Shares:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {getRandomInt(1000, 10000)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {/* {ingredientsproductscurrentItems &&
          ingredientsproductscurrentItems.length > 0 ? (
            ingredientsproductscurrentItems.map((product, index) => ( */}
            {slicedData.length > 0? (
                        slicedData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < ingredientsproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.baseSponsorImage
                            ? product.baseSponsorImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < ingredientsproductscurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
            brandproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.brandIcon ? product.brandIcon : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
            brandproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencerscurrentItems && influencerscurrentItems.length > 0 ? (
            influencerscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencerscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.username}
                      </div>
                      {product.email}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencerscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const TPtabContentEX = [
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {receipesproductscurrentItems &&
          receipesproductscurrentItems.length > 0 ? (
            receipesproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < receipesproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3" style={{ width: "897px" }}>
                      <div className="fw-500 text-inverse">{product.name}</div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < receipesproductscurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <div className="spinner-border text-success"></div>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {/* {ingredientsproductscurrentItems &&
          ingredientsproductscurrentItems.length > 0 ? (
            ingredientsproductscurrentItems.map((product, index) => ( */}
            {staticData.length > 0? (
                        staticData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < ingredientsproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.baseSponsorImage
                            ? product.baseSponsorImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3" style={{ width: "897px" }}>
                      <div className="fw-500 text-inverse">{product.name}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < ingredientsproductscurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
            brandproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3" style={{ width: "897px" }}>
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
            brandproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3" style={{ width: "897px" }}>
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencerscurrentItems && influencerscurrentItems.length > 0 ? (
            influencerscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencerscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3" style={{ width: "897px" }}>
                      <div className="fw-500 text-inverse">
                        {product.username}
                      </div>
                      {product.email}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencerscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const Mixlistcontent = [
    <div className="table-responsive">
     {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) : receipesmixlistcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {receipesmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < receipesmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < receipesmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                </tr>
          ))}
      </tbody>
    </table>
  )}
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencersmixlistcurrentItems &&
          influencersmixlistcurrentItems.length > 0 ? (
            influencersmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencersmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencersmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandmixlistcurrentItems && brandmixlistcurrentItems.length > 0 ? (
            brandmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandmixlistcurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const MixlistcontentEX = [
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {receipesmixlistcurrentItems &&
          receipesmixlistcurrentItems.length > 0 ? (
            receipesmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "1020px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < receipesmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < receipesmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencersmixlistcurrentItems &&
          influencersmixlistcurrentItems.length > 0 ? (
            influencersmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "897px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencersmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencersmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandmixlistcurrentItems && brandmixlistcurrentItems.length > 0 ? (
            brandmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "897px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandmixlistcurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const handleChangeStart = (date) => {
    setStartDate(date);
  };

  const handleChangeStartCrafted = (date) => {
    setcraftedStartDate(date);
  };

  const handleChangeStartActive = (date) => {
    setActiveStartDate(date);
  };

  const handleChangeEnd = (date) => {
    setEndDate(date);
  };

  const handleChangeEndCrafted = (date) => {
    setcraftedEndDate(date);
  };

  const handleChangeEndActive = (date) => {
    setActiveEndDate(date);
  };

  const handleAgeOptionChange = (selectedOption) => {
    setSelectedAgeOption(selectedOption);
  };

  const handleAgeOptionChangeCrafted = (selectedOption) => {
    setcraftedSelectedAgeOption(selectedOption);
  };

  const handleAgeOptionChangeActive = (selectedOption) => {
    setActiveSelectedAgeOption(selectedOption);
  };

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const calculateTimestampForAge = (age) => {
    const birthDate = new Date();
    birthDate.setFullYear(birthDate.getFullYear() - age);
    birthDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return Math.floor(birthDate.getTime() / 1000);
  };

  const DashUserTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (selectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(startDate);
        endTime = dateToTimestampInSeconds(endDate);
      }

      let ageStart = 0;
      let ageEnd = timestampInSeconds; // Default to current timestamp if no age filter is applied

      if (selectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = selectedAgeOption.value.split("-");
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardusertileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=User&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataUserTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CraftedTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (craftedselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(craftedstartDate);
        endTime = dateToTimestampInSeconds(craftedendDate);
      }

      let ageStart = 0;
      let ageEnd = timestampInSeconds; // Default to current timestamp if no age filter is applied

      if (craftedselectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = craftedselectedAgeOption.value.split("-");
        // console.log(startAge, endAge);
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardcraftedtileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=StatUserEventMake&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataCraftedTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ActiveTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (activeselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(activestartDate);
        endTime = dateToTimestampInSeconds(activeendDate);
      }

      let ageStart = 0;
      let ageEnd = 0; // Default to current timestamp if no age filter is applied

      if (activeselectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = activeselectedAgeOption.value.split("-");
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardactiveusertileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataActiveTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ProductTileInfo = async (filterValue) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardproducttileinfo?startTime=0&endTime=0&filter=3&datefilter=0`
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
    }
  };

  const ReceipesTileInfo = async (filterValue) => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewrecipetileinfo`
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const MixlistTileInfo = async (filterValue) => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewplaylisttileinfo`
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDateOptionChange = (selectedOption) => {
    setSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      // Custom Date Range option selected
      setCustomDateRange(true); // Enable custom date range
    } else {
      setCustomDateRange(false); // Disable custom date range
    }
  };

  const handleDateOptionChangeCrafted = (selectedOption) => {
    setCraftedSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setcraftedCustomDateRange(true);
    } else {
      setcraftedCustomDateRange(false);
    }
  };

  const handleDateOptionChangeActive = (selectedOption) => {
    setActiveSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setActiveCustomDateRange(true);
    } else {
      setActiveCustomDateRange(false);
    }
  };

  const labels = DashUserTile.map((item) => item.label);
  const barvalues = DashUserTile.map((item) => item.value);

  const craftedlabels = CraftedTile.map((item) => item.label);
  const craftedlinevalues = CraftedTile.map((item) => item.value);

  const activelabels = ActiveTile.map((item) => item.label);
  const activebarvalues = ActiveTile.map((item) => item.value);
  const activebarvaluess = ActiveTile.map((item) => item.activeUservalue);

  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();

  var TRUbarChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, indigo],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var TRUbarChartData = [
    { name: "Website", data: [] },
    { name: "Apps", data: barvalues },
  ];

  var CraftedChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    colors: ["#96E2E3", gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 4 },
    grid: {
      borderColor: borderColor,
      row: {
        colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: { size: 6 },
    xaxis: {
      categories: craftedlabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      // min: 5, max: 40,
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };

  var CraftedChartData = [{ name: "Cocktails", data: craftedlinevalues }];
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var NewUserChartOptions = {
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    colors: [themeColor, "#7897FF", "#B5B5B5"],
    grid: { borderColor: borderColor },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      title: { text: "" },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    fill: { opacity: 1 },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
    tooltip: {
      y: {
        formatter: function (val) {
          return " " + val + " ";
        },
      },
    },
  };

  var NewUserChartData = [
    { name: "New Users", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] },
    { name: "Active Users", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] },
    { name: "Non-Active Users", data: [35, 41, 36, 26, 45, 48, 52, 53, 41] },
  ];

  var HardwareChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    colors: ["#96E2E3", gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 4 },
    grid: {
      borderColor: borderColor,
      row: {
        colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: { size: 6 },
    xaxis: {
      categories: craftedlabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      // min: 5, max: 40,
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };

  var HardwareChartData = [{ name: "Hardware", data: craftedlinevalues }];

  var AUbarChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, indigo],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: activelabels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };
  var AUbarChartOptionsmain = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, "gray"],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: activelabels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: false,
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var AUbarChartData = [
    { name: "New User", data: activebarvalues },
    { name: "Active User", data: [18,21,9,22,17,11,33,19,16,8,25,23] },
  ];

  //Expand Data Modal
  const TotalUserExpand = (
    <>
      <h3>TOTAL REGISTERED USER</h3>
      <div className="row">
        {data && data.userActive && (
          <h2 className="col-xl-3">
          {/* {data.userActive} */}
          1.98 M
          </h2>
        )}

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={selectedDateOption}
            onChange={handleDateOptionChange}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {customDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleChangeStart}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleChangeEnd}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={selectedAgeOption}
            onChange={handleAgeOptionChange}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={TRUbarChartOptions}
          series={TRUbarChartData}
          height="600"
        />
      </div>

      {data && (
        <h5>
          <i className="fas fa-angle-up"></i>{" "}
          {data.userMoreThenPrevWeek.toFixed(2)}% more that last week
        </h5>
      )}
      {data && (
        <h5>
          <i className="fas fa-user"></i> {data.userlastWeekUser} new visitors
        </h5>
      )}
    </>
  );
  const CraftedCocktailExpand = (
    <>
      <h3>CRAFTED COCKTAILS</h3>
      <div className="row">
        {data && <h2 className="col-xl-3">
        {data.make}
        </h2>}

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={craftedselectedDateOption}
            onChange={handleDateOptionChangeCrafted}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {craftedcustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={craftedstartDate}
                  onChange={handleChangeStartCrafted}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={craftedendDate}
                  onChange={handleChangeEndCrafted}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={craftedselectedAgeOption}
            onChange={handleAgeOptionChangeCrafted}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="line"
          options={CraftedChartOptions}
          series={CraftedChartData}
          height={600}
        />
      </div>

      {/* {data && (
        <h5>
          <i className="fas fa-angle-up"></i>
          {data.makeMoreThenPrevWeek.toFixed(2)}% more that last week
        </h5>
      )} */}
      {data && (
        <h5>
          <i className="fas fa-user"></i> {data.lastWeekMake} new crafted
        </h5>
      )}
    </>
  );
  const NewUserExpand = (
    <>
      <h3>NEW USER</h3>
      <div className="row">
        <h2 className="col-xl-3">{2193}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select options={dateOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select options={AgeOptions} classNamePrefix="react-select" />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={NewUserChartOptions}
          height={600}
          series={NewUserChartData}
        />
      </div>

      <h5>
        <i className="fas fa-angle-up"></i> {36}% more that last week
      </h5>
      <h5>
        <i className="fas fa-user"></i> {99} new visitors
      </h5>
    </>
  );
  const HardwareExpand = (
    <>
      <h3>HARDWARE DEPLOYED</h3>
      <div className="row">
        <h2 className="col-xl-3">{96341}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select options={dateOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="line"
          options={HardwareChartOptions}
          series={HardwareChartData}
          height={600}
        />
      </div>

      <h5>
        <i className="fas fa-angle-up"></i> {36}% more that last week
      </h5>
      <h5>
        <i className="fas fa-user"></i> {99} unique visitors
      </h5>
    </>
  );
  const ActiveUserExpand = (
    <>
      <h3>ACTIVE USER</h3>
      <div className="row">
        <h2 className="col-xl-2">{18463}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={activeselectedDateOption}
            onChange={handleDateOptionChangeActive}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {activecustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={activestartDate}
                  onChange={handleChangeStartActive}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={activeendDate}
                  onChange={handleChangeEndActive}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={activeselectedAgeOption}
            onChange={handleAgeOptionChangeActive}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2">
          <label htmlFor="location">Users</label>
          <Select options={userOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="row align-items-center mb-1">
        {/* <div className="col-6 text-right mb-1">
                  <h3 className="mb-0">{500}</h3>
                </div> */}
        <div className="col-10 text-end mt-4">
          <h2 className="mb-0" style={{ color: "#3CD25D" }}>
            {"0"}
          </h2>
          <p className="mb-0" style={{ color: "gray" }}>
            {"New Active User"}
          </p>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={AUbarChartOptions}
          series={AUbarChartData}
          height={600}
        />
      </div>

      <h5>
        <i className="fas fa-angle-up"></i> {36}% more active user than last
        week
      </h5>
    </>
  );
  const GeographicalExpand = (
    <>
      {/* <h3>GEOGRAPHICAL LOCATION</h3>
      <div className="row">
        <h2 className="col-xl-2">{0}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select options={dateOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select options={AgeOptions} classNamePrefix="react-select" />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="ratio ratio-21x9 mb-3">
        <div id="world-map" className="jvectormap-without-padding"></div>
      </div> */}
      <div className="row gx-4">
        <div className="col-lg-12 mb-3  mb-lg-0">
          <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Active User</th>
                <th>Cocktail Crafted</th>
              </tr>
              <tr className="text-inverse text-opacity-75">
                <th className="w-50 ">COUNTRY</th>
                <th className="w-25">
                  <div className="w-100">
                    <label className="w-50">Count</label>
                    <label className="w-50">PCT%</label>
                  </div>
                </th>
                <th className="w-25">
                  <div className="w-100">
                    <label className="w-50">Count</label>
                    <label className="w-50">PCT%</label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <tr key={index} className={country.class}>
                        <td>{country.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <div key={index} className={country.class}>
                        <label className="w-50">{country.visits}</label>
                        <label className="w-50">{country.pct}</label>
                      </div>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <div key={index} className={country.class}>
                        <label className="w-50">{country.ccvisits}</label>
                        <label className="w-50">{country.ccpct}</label>
                      </div>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="col-lg-6">
                  <Card>
                    <CardBody className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="w-70px">
                          {sourceChartData && sourceChartData.length > 0 && (
                            <Chart
                              type="donut"
                              height="70"
                              options={chartOptions["donut"]}
                              series={sourceChartData}
                            />
                          )}
                        </div>
                        <div className="flex-1 ps-2">
                          <table className="w-100 small mb-0 text-inverse text-opacity-60">
                            <tbody>
                              {sourceData && sourceData.length > 0 ? (
                                sourceData.map((source, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className={
                                            "w-6px h-6px rounded-pill me-2 " +
                                            source.class
                                          }
                                        ></div>
                                        {source.name}
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {source.percentage}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No records found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div> */}
      </div>
    </>
  );

  const TopProductsExpand = (
    <>
      <h3>TOP PRODUCTS</h3>
      <div className="row">
        <div className="col-md-4 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions} classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">
          <div class="row">
            <div class="col-md-3">
              <label htmlFor="users">Users</label>
            </div>
            <div class="col-md-6">
              <Form.Select id="users">
                <option value="app">App</option>
                <option value="website">Website</option>
                <option value="app_device">App + Device</option>
                <option value="website_device">Website + Device</option>
                <option value="subscribers">Subscribers</option>
              </Form.Select>
            </div>
          </div>
        </div> */}

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {producttabsEX.map((tab, index) => (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={`nav-link ${tab.active ? "active" : ""}`}
                    id={`${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.id}`}
                    type="button"
                    role="tab"
                    aria-controls={tab.id}
                    aria-selected={tab.active ? "true" : "false"}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {producttabsEX.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {TPtabContentEX[index]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-10"></div>
        <div className="col-md-2">
          <nav className="">
            <ul className="pagination">
              {(ReceipeProductTile.length ||
                IngredientsProductTile.length ||
                BrandsProductTile.length ||
                BarsProductTile.length ||
                InfluencerProductTile.length) > 0 && (
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    &lt;
                  </button>
                </li>
              )}
              {Array.from(
                {
                  length: Math.ceil(
                    (ReceipeProductTile.length ||
                      IngredientsProductTile.length ||
                      BrandsProductTile.length ||
                      BarsProductTile.length ||
                      InfluencerProductTile.length) / itemsPerPage
                  ),
                },
                (_, i) => i + 1
              ).map((page) => (
                <li
                  key={page}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <button className="page-link" onClick={() => paginate(page)}>
                    {page}
                  </button>
                </li>
              ))}
              {(ReceipeProductTile.length ||
                IngredientsProductTile.length ||
                BrandsProductTile.length ||
                BarsProductTile.length ||
                InfluencerProductTile.length) > 0 && (
                <li
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(
                      (ReceipeProductTile.length ||
                        IngredientsProductTile.length ||
                        BrandsProductTile.length ||
                        BarsProductTile.length ||
                        InfluencerProductTile.length) / itemsPerPage
                    )
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    &gt;
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );

  const ReceipesProductsExpand = (
    <>
      <h3>NEW RECIPES</h3>
      <div className="row">
        <div className="col-md-4 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions} classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">
          <div class="row">
            <div class="col-md-3">
              <label htmlFor="users">Users</label>
            </div>
            <div class="col-md-6">
              <Form.Select id="users">
                <option value="app">App</option>
                <option value="website">Website</option>
                <option value="app_device">App + Device</option>
                <option value="website_device">Website + Device</option>
                <option value="subscribers">Subscribers</option>
              </Form.Select>
            </div>
          </div>
        </div> */}

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {newreceipestabsEX.map((tab, index) => (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={`nav-link ${tab.active ? "active" : ""}`}
                    id={`${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.id}`}
                    type="button"
                    role="tab"
                    aria-controls={tab.id}
                    aria-selected={tab.active ? "true" : "false"}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {newreceipestabsEX.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {ReceipestabContentEX[index]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-10"></div>
        <div className="col-md-2">
          <nav className="">
            <ul className="pagination">
              {(UserReceipeTile.length ||
                InfluencerReceipeTile.length ||
                BrandReceipeTile.length) > 0 && (
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    &lt;
                  </button>
                </li>
              )}
              {Array.from(
                {
                  length: Math.ceil(
                    (UserReceipeTile.length ||
                      InfluencerReceipeTile.length ||
                      BrandReceipeTile.length) / itemsPerPage
                  ),
                },
                (_, i) => i + 1
              ).map((page) => (
                <li
                  key={page}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <button className="page-link" onClick={() => paginate(page)}>
                    {page}
                  </button>
                </li>
              ))}
              {(UserReceipeTile.length ||
                InfluencerReceipeTile.length ||
                BrandReceipeTile.length) > 0 && (
                <li
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(
                      (UserReceipeTile.length ||
                        InfluencerReceipeTile.length ||
                        BrandReceipeTile.length) / itemsPerPage
                    )
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    &gt;
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );

  const NewMixlistExpand = (
    <>
      <h3>New Mixlist</h3>
      <div className="row">
        <div className="col-md-4 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions} classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {newmixlisttabsEX.map((tab, index) => (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={`nav-link ${tab.active ? "active" : ""}`}
                    id={`${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.id}`}
                    type="button"
                    role="tab"
                    aria-controls={tab.id}
                    aria-selected={tab.active ? "true" : "false"}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {newmixlisttabsEX.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {MixlistcontentEX[index]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-10"></div>
        <div className="col-md-2">
          <nav className="">
            <ul className="pagination">
              {(MixReceipeTile.length ||
                MixInfluencersTile.length ||
                MixBrandTile.length) > 0 && (
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    &lt;
                  </button>
                </li>
              )}
              {Array.from(
                {
                  length: Math.ceil(
                    (MixReceipeTile.length ||
                      MixInfluencersTile.length ||
                      MixBrandTile.length) / itemsPerPage
                  ),
                },
                (_, i) => i + 1
              ).map((page) => (
                <li
                  key={page}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <button className="page-link" onClick={() => paginate(page)}>
                    {page}
                  </button>
                </li>
              ))}
              {(MixReceipeTile.length ||
                MixInfluencersTile.length ||
                MixBrandTile.length) > 0 && (
                <li
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(
                      (MixReceipeTile.length ||
                        MixInfluencersTile.length ||
                        MixBrandTile.length) / itemsPerPage
                    )
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    &gt;
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
  renderMap();
  return (
    <>
      <div className="row">
        {/* Total Registered User- Start*/}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <h6 className="flex-grow-1">{"TOTAL REGISTERED USERS"}</h6>
                <ModalComponent cardBodyContent={TotalUserExpand} />
              </div>
              <div className="row align-items-center">
                <div className="col-5 mt-3 mb-3">
                  <h3 className="mb-0">
                    {/* {data && data.userActive ? (
                      data.userActive
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )} */}
                    1.98 M
                  </h3>
                </div>
                {/* <div className="col-7 mt-3 mb-3"> */}
                <div className="col-7 mt-3">
                  {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 0)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={TRUbarChartData}
                          key={index}
                        />
                      ))}
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
                <i className="fas fa-angle-up"></i>
                {data && data.userMoreThenPrevWeek.toFixed(2)}% more than last
                week <br></br>
                <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                new visitors
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 33.89% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Total Registered User - End*/}
        {/* Crafted Cocktailes - Start*/}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">{"CRAFTED COCKTAILS"}</h6>
                <ModalComponent cardBodyContent={CraftedCocktailExpand} />
              </div>
              <div className="row align-items-center mb-1">
                <div className="col-12 text-center mb-2">
                  <h3 className="mb-0">
                    {data && data.make ? (
                      data.make
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 961447 */}
                  </h3>
                </div>
                {/* <div className="col-12 text-center">
                  <h6 className="mb-0" style={{ color: "#3CD25D" }}>
                    {data && data.makeMoreThenPrevWeek
                      ? data.makeMoreThenPrevWeek.toFixed(2) + "%"
                      : "Loading..."}
                  </h6>
                  <p className="mb-0" style={{ color: "#3CD25D" }}>
                    {"New Crafted Cocktails"}
                  </p>
                </div> */}

                {/* <div className="col-12 text-end mt-1">
                  <h6 className="mb-0" style={{ color: "gray" }}>
                    {"Last 7d"}
                  </h6>
                </div> */}
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate mt-2">
                {/* <i className="fas fa-angle-up"></i>{" "} */}
          {/* {data && data.makeMoreThenPrevWeek.toFixed(2)}% more than last week  */}
          <br></br>
                <i className="fas fa-glass-martini"></i>{" "}
                {data && data.lastWeekMake} new crafted cocktails
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 43.66% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Crafted Cocktailes - End*/}
        {/* New Users - Start */}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">{"NEW USERS"}</h6>
                <ModalComponent cardBodyContent={NewUserExpand} />
              </div>
              <div className="row align-items-center mb-1">
                <div className="col-12 text-center mb-2">
                  <h3 className="mb-0">
                    {/* {data && data.userActive ? (
                      data.userActive
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )} */}
                    2193
                  </h3>
                </div>
                {/* <div className="col-12 text-center">
                  <h6 className="mb-0" style={{ color: "#3CD25D" }}>
                    {data && data.makeMoreThenPrevWeek
                      ? data.makeMoreThenPrevWeek.toFixed(2) + "%"
                      : "Loading..."}
                  </h6>
                  <p className="mb-0" style={{ color: "#3CD25D" }}>
                    {"New Crafted Cocktails"}
                  </p>
                </div> */}

                {/* <div className="col-12 text-end mt-1">
                  <h6 className="mb-0" style={{ color: "gray" }}>
                    {"Last 7d"}
                  </h6>
                </div> */}
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate mt-2">
                <i className="fas fa-angle-up"></i> 21.56% more than last week{" "}
                <br></br>
                <i className="fas fa-user"></i> 33 new user
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 18.96% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Users - End */}
        {/* Hardware Deployed - Start */}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <h6 className="flex-grow-1">{"HARDWARE DEPLOYED"}</h6>
                <ModalComponent cardBodyContent={HardwareExpand} />
              </div>
              <div className="row align-items-center">
                <div className="col-4 mt-3 mb-3">
                  <h3 className="mb-0">{9634}</h3>
                </div>
                <div className="col-8 mt-3 mb-3">
                  {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 1)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={stat.chartData}
                          key={index}
                        />
                      ))}
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
                <i className="fas fa-angle-up"></i> 30% more than last week{" "}
                <br></br>
                <i className="fas fa-microchip"></i> 46 new hardware deployed
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 36% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Hardware Deployed - End */}

        {/* {statsData &&
          statsData.length > 0 &&
          statsData.map((stat, index) => (
            <div className="col-xl-3 col-lg-6" key={index}>
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">{stat.title}</span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div className="col-7">
                      <h3 className="mb-0">{stat.total}</h3>
                    </div>
                    <div className="col-5">
                      <div className="mt-n2">
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={stat.chartData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="small text-inverse text-opacity-50 text-truncate">
                    {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))} */}
        {/* Active User -- Starts */}

        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">ACTIVE USER</h5>
                <ModalComponent cardBodyContent={ActiveUserExpand} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h3 className="mb-0">
                    {/* {data && data.allActiveUser ? data.allActiveUser : 0} */}
                                        18463
                  </h3>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                      96
                  </h5>
                  <p className="mb-0" style={{ color: "gray" }}>
                    {"New Active User"}
                  </p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                <Chart
                  type="bar"
                  height="100%"
                  options={AUbarChartOptionsmain}
                  series={AUbarChartData}
                />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
              {/* <div className="row">
                {serverData && serverData.length > 0 ? (
                  serverData.map((server, index) => (
                    <div className="col-lg-6 mb-3 mb-lg-0" key={index}>
                      <div className="d-flex">
                        <div className="w-50px pt-3">
                          <Chart
                            type={server.chartType}
                            height={server.chartHeight}
                            options={chartOptions[server.chartType]}
                            series={server.chartData}
                          />
                        </div>
                        <div className="ps-3 flex-1">
                          <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">
                            {server.name}
                          </div>
                          <div className="mb-2 fs-5 text-truncate">
                            {server.total}
                          </div>
                          <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                            <div
                              className="progress-bar bg-theme"
                              style={{ width: server.progress }}
                            ></div>
                          </div>
                          <div className="fs-11px text-inverse text-opacity-50 mb-2 text-truncate">
                            {server.time}
                          </div>
                          {server.info.length > 0 ? (
                            server.info.map((info, index) => (
                              <div
                                className="d-flex align-items-center small"
                                key={index}
                              >
                                <i
                                  className={
                                    "bi bi-circle-fill fs-6px me-2 " +
                                    info.class
                                  }
                                ></i>
                                <div className="flex-1">{info.title}</div>
                                <div>{info.value}</div>
                              </div>
                            ))
                          ) : (
                            <div>No records found</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12">No records found</div>
                )}
              </div> */}
            </CardBody>
          </Card>
        </div>
        {/* Active User -- Ends */}
        {/* Geographical User -- Starts */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">GEOGRAPHICAL LOCATION</h5>
                <Geomapexpand cardBodyContent={GeographicalExpand} />
              </div>
              <div className="ratio ratio-21x9 mb-4">
                <div
                  id="world-map"
                  className="jvectormap-without-padding"
                ></div>
              </div>
              <div className="row gx-4">
                <div className="col-lg-12 mb-3  mb-lg-0">
                  <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Active User</th>
                        <th>Cocktail Crafted</th>
                      </tr>
                      <tr className="text-inverse text-opacity-75">
                        <th className="w-50 ">COUNTRY</th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <tr key={index} className={country.class}>
                                <td>{country.name}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">{country.visits}</label>
                                <label className="w-50">{country.pct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">{country.ccvisits}</label>
                                <label className="w-50">{country.ccpct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <div className="col-lg-6">
                  <Card>
                    <CardBody className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="w-70px">
                          {sourceChartData && sourceChartData.length > 0 && (
                            <Chart
                              type="donut"
                              height="70"
                              options={chartOptions["donut"]}
                              series={sourceChartData}
                            />
                          )}
                        </div>
                        <div className="flex-1 ps-2">
                          <table className="w-100 small mb-0 text-inverse text-opacity-60">
                            <tbody>
                              {sourceData && sourceData.length > 0 ? (
                                sourceData.map((source, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className={
                                            "w-6px h-6px rounded-pill me-2 " +
                                            source.class
                                          }
                                        ></div>
                                        {source.name}
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {source.percentage}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No records found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Geographical User -- Ends */}
        {/* Top Products -- Starts */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">TOP PRODUCTS</h5>
                <ModalComponent cardBodyContent={TopProductsExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {producttabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {producttabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {TPtabContent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Top Products -- Ends */}
        {/* New Receipes -- Start*/}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">NEW RECIPES</h5>
                <ModalComponent cardBodyContent={ReceipesProductsExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {newreceipestabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {newreceipestabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {ReceipestabContent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Receipes -- End*/}
        {/* New Mixlist-- Start*/}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">NEW MIXLIST</h5>
                <ModalComponent cardBodyContent={NewMixlistExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {newmixlisttabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {newmixlisttabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {Mixlistcontent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Mixlist -- End */}
      </div>
    </>
  );
}

export default Dashboard;
